// Here you can add other styles
.astrisk::after {
    content: "*";
    color: red;
}

.filters-row .form-control{
    border-radius: 0;
    border:  1px solid transparent;
}

.filters-row .form-control:focus {
    border: 1px solid black;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .table-header-color th{
    // background-color: #00A6B9;
    // color: #fff;

    position: sticky;
    top: -1px;
    background-color:  #00A6B9; /* Dark background for better contrast with white text */
    color: white; /* White text */
   // z-index: 10; /* Ensures the header stays above the table rows */
    padding: 12px;
    border-bottom: 2px solid #dee2e6; /* Optional: Adds a bottom border */
    
  }

  .header-div .dropdown-toggle::after{
    display: none;
  }


  .toast {
    --cui-toast-bg: rgba(255, 255, 255, 1);
  }

  .modal.modal-static .modal-dialog {
    transform: none !important;
}

.no_wrap{
  white-space: nowrap;
}


.followup-btn{
  background-color: #00A6B9;
  color: #fff !important;
  padding: 5px;
  border-radius: 5px;
  text-decoration: none !important;
}